import React from "react"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Note from "../../../../../components/note"
import PaperStack from "../../../../../components/paper-stack"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import TaskSidebar from "../../../../../components/task-sidebar"

import PoemFruehlingExplained from "../../../../../content/kurse/jahreszeiten/01-jahreszeitengedicht/poem-fruehling-explained"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/jahreszeiten/01-jahreszeitengedicht/lesen-und-einpraegen/aufgabe" />
      }
    >
      <Seo title="Lesen & Einprägen" />
      <Stack>
        <Heading as="h1" level={2}>
          Lesen & Einprägen
        </Heading>
        <TaskSidebar
          main={
            <PaperStack>
              <PoemFruehlingExplained />
            </PaperStack>
          }
          sidebar={
            <Note variant="task">
              Lies dir das Gedicht noch einmal durch und versuche, es dir
              einzuprägen. Du kannst es auch mehrmals lesen.
            </Note>
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
